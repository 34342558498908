.ques{
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}


.ques h1{
    font-size: 18px;
    font-weight: 400;
    color: white;
    word-spacing: 2px;
    line-height: 1.7;
}

.ans{
    max-height: 0;
    overflow: hidden;
    transition: all 0.5s cubic-bezier(0,1,0,1);
    font-size: 16px;
    font-weight: 300;
    color: white;
    word-spacing: 2px;
}

.ans.show{
    height: auto;
    max-height: 9999px;
    transition: all 0.5s cubic-bezier(1,0,1,0);
}

.Accordionmain{
    margin-top: 100px;
}

.Accordionmain span{
    font-size: 34px;
    color: white;
    font-weight: 300;
}

.question{
    display: flex;
    justify-content: center;
}

hr{
    color: white;
}