@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700;800;900&display=swap');

* {
    font-family: 'Rubik', sans-serif;
}


.heading h1 {

    text-align: center;
    font-size: 34px;
    font-weight: 700;
    padding-top: 50px;
    color: #bc132a;

}

.clients {
    background-color: #24262b !important;
}

.para p {
    text-align: center;
    font-size: 34px;
    font-weight: 800;
}

.logo1 {
    display: flex;
    justify-content: center;
}

.logo1 {
    animation-name: mymove;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    animation-duration: 3s;
}

@keyframes mymove {
    from {
        transform: translateY(0px);
    }

    to {
        transform: translateY(20px);
    }
}


.head h2 {
    text-align: center;
    padding-top: 25px;
    font-weight: 700;
    color: #bc132a;
}

.head1 h1 {
    text-align: center;
    font-size: 34px;
    font-weight: 700;
    padding-top: 30px;
    padding-bottom: 20px;
    color: #ddd;
}


.clients .animated-typing2 h1{
    font-size: 34px;
    font-weight: 700;
    color: #ddd;
}

.clients .animated-typing2{
    margin-top: 10px;
}

/* .clients button {
    display: none !important;
} */

.head2 h1 {
    text-align: center;
    font-size: 34px;
    font-weight: 700;
    padding-top: 20px;
    padding-bottom: 50px;
    color: #ddd;
}

.head4 h1 {
    text-align: center;
    font-size: 34px;
    font-weight: 700;
    color: #24262b;
}

.clients .head4 {
    background-color: #ddd;
    padding: 50px 0px;
}


.head5 h1 {
    text-align: center;
    font-weight: 700;
    padding-top: 100px;
    color: #bc132a;
    font-size: 34px;
}

.head6 h1 {
    text-align: center;
    font-size: 18px;
    font-weight: 400;
    padding-bottom: 50px;
    color: #fff;
}

/* .background {
    background-image: url("../../Assests/clientimg/vector.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    margin-top: 50px;

} */

.head2 {
    padding-bottom: 50px;
    color: white;
}

.bg {
    background-size: 400% 400%;
    animation: gradient 5s ease infinite;

}

.clients .bannertitle{
    padding: 10px !important;
    position: relative;
    top: 25%;
}

.clients .bannertitle p {
    color: white;
    font-size: 24px;
    font-weight: 300;
    padding-left: 10px;
    padding-bottom: 0px;
}

@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}

.icon1 {
    display: flex;
    justify-content: center;
    cursor: pointer;
}

.icons2 {
    display: flex;
    justify-content: center;
    cursor: pointer;
}

.animated-typing2 span {
    font-size: 34px;
    font-weight: 800;
}

.clientscontainer {
    padding: 0px;
}

.animated-typing2 {
    text-align: center;
    font-size: 22px;
    font-weight: 800;
    padding-bottom: 0px !important;
    color: #ffffff !important;
    font-weight: bold;
    margin-top: 40px;
}

.img1 {
    padding-left: 35px;
    padding-right: 35px;
}



/* .img5{
   
    padding-left: 70px;
} */
.img2 {

    padding-left: 35px;
    padding-right: 35px;
}

/* .icon1 img:hover{
    transform: scale(1.2);
    transition: scale 300ms;
    
} */

.img5 img:hover{
    transform: scale(1.2);
    transition: all 300ms;
}

.img1 img:hover{
    transform: scale(1.2);
    transition: all 300ms;
}

.img2 img:hover{
    transform: scale(1.2);
    transition: all 300ms;
}

.img3 img:hover{
    transform: scale(1.2);
    transition: all 300ms;
}

.img4 img:hover{
    transform: scale(1.2);
    transition: all 300ms;
}


.img3 {

    padding-left: 35px;
    padding-right: 35px;
}

.icon1 img:hover{
    transform: scale(1.2);
}

.img4 {

    padding-left: 35px;
    padding-right: 35px;
}


.img5 {

    padding-left: 35px;
    padding-right: 35px;
}


.industries {
    font-size: 22px;
    color: #ffffff;
    display: flex;
    justify-content: center;
}

.icons2 {
    margin: 45px 0px;

}
.clients{
    background-color: #212529;
}

.img20{
    display: flex;
    justify-content: center !important;
}

@media only screen and (min-width: 320px) and (max-width: 480px){
    .icon1{
        display: block;
    }

    .icons2{
        display: block;
    }
}
