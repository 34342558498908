@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700;800;900&display=swap');

* {
  font-family: 'Rubik', sans-serif;
}

.frontSide img {
  border-radius: 1rem;
}

.myCard {
  background-color: transparent;
  width: 330px;
  height: 400px;
  perspective: 1000px;
  margin-left: 40px;
  margin-top: 50px;
}

.backSide .title {
  font-size: 24px !important;
  font-weight: 500;
  /* text-align: center; */
  margin-top: 10px;
  color: #24262b;
}

.innerCard {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
  cursor: pointer;
}

.myCard:hover .innerCard {
  transform: rotateY(180deg);
}

.frontSide,
.backSide {
  position: absolute;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  /* border: 1px solid rgba(255, 255, 255, 0.8); */
  border-radius: 1rem;
  color: #24262b;
  box-shadow: 0 0 0.3em rgba(185, 185, 185, 0.5);
  font-weight: 700;
}



.backSide,
.backSide::before {
  background: #dddddd;
}


.backSide {
  transform: rotateY(180deg);
  text-align: start !important;
}

.backSide p {
  font-size: 18px;
  font-weight: 300;
  padding: 10px 20px 0px 20px;
}

/* .frontSide::before,
  .backSide::before {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    content: '';
    width: 110%;
    height: 110%;
    position: absolute;
    z-index: -1;
    border-radius: 1em;
    filter: blur(20px);
    animation: animate 5s linear infinite;
  } */

@keyframes animate {
  0% {
    opacity: 0.3;
  }

  80% {
    opacity: 1;
  }

  100% {
    opacity: 0.3;
  }
}

@media only screen and (min-width: 320px) and (max-width: 1280px){
  .myCard{
    margin-left: 0px;
  }
}