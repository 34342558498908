@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700;800;900&display=swap');

*{
    font-family: 'Rubik', sans-serif;
}

.navbar-expand-lg .navbar-nav .nav-link {
    padding: 0 1rem !important;
    font-size: 16px;
    color: rgb(255, 255, 255);
    font-weight: 400;
}

.Navbar button {
    padding: 0.5rem 1rem !important;
    font-size: 16px !important;
    font-weight: 400 !important;
    /* background-color: #bc132a !important; */
    border: #bc132a !important;
}

.Navbar{
    background: #24262b !important;
}

.navbar-brand{
    cursor: pointer;
}

.Navbar .navbar-nav {
    display: flex;
    align-items: center;
    justify-content: center;
}

.Navbar .navbar-nav .nav-link {
    padding: 0.5rem 1rem;
    text-align: left;
}

.nav-item {
    width: 50%;
}

.Navbar .navbar-nav .drop {
    position: static;
}

/* Optionally, you can add some spacing between the items */
/* .Navbar .navbar-nav .nav-link+.nav-link {
    margin-left: 1rem;
} */

.navbar-expand-lg .navbar-nav .dropdown-menu {
    width: 100%;
}

.final {
    display: flex;
    margin: 0px 120px;
}

.navbar-toggler{
    background-color: #ddd;
}

@media only screen and (min-width: 320px) and (max-width: 820px){
    .final{
        display: block;
        margin: 0px !important;
    }
    .Navbar .navbar-nav {
        display: block;
        justify-content: start;

    }
    .final a{
        margin-top: 10px;
    }
    .nav-item{
        width: 100% !important;
    }
    .final .drop3{
        margin-left: 0px !important;
    }
    .navbutton{
        background-color: #ddd;
        font-weight: 400;
        color: #24262b;
    }
    .navbuttondiv{
        padding: 0.2rem .6rem !important;
    }
    .navbar-toggler:focus{
        box-shadow: none !important;
    }
}




@media only screen and (min-width: 1024px) and (max-width: 1280px){
    .final{
        margin: 0px 0px;
    }
}

.bg-body-tertiary{
    --bs-bg-opacity: 0 !important;
    padding-top: 10px;
}

.final .drop1{
    background-color:  #ddd;
    border-radius: 8px;
    margin-right: 20px;
}

.final .drop2{
    background-color: #ddd;
    border-radius: 8px;
}

.final .drop3{
    background: #ddd;
    border-radius: 8px;
    margin-left: 20px;
}


.dropdown-menu{
    background: transparent;
}

.dropdown-item{
    font-size: 26px;
    font-weight: 700;
}

.dropdown-item p{
    font-size: 16px;
    font-weight: 400;
}

.fixed-top{
    position: absolute;
}