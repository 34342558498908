.bannerservice h1{
    font-size: 28px;
    font-weight: 600;
    text-align: center;
    color: white;
}


.bannerservice{
    padding-top: 40px;
}

.bannericons{
    margin-top: 50px;
    text-align: center;
    display: flex;
    justify-content: space-around;
    position: relative;
}

.bannericons img{
    position: relative;
    height: 100px;
    width: 100px;
    padding: 10px;
    background: #24262b;
    /* z-index: 1; */
}

.hightlight{
    color: #FFD519;
}


.bannericons>div{
    position: relative;
}
.bannericons>div::after{
    content: "";
    position: absolute;
    border: 2px dashed rgb(255, 255, 255);
    border-radius: 50%;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    animation: rotateImg 4s linear infinite;
    /* background-color: white; */
    /* z-index: 1; */
}

.bannericons>div:first-child:before{
    content: "";
    position: absolute;
    border-bottom: 2px dashed rgb(255, 255, 255);
    height: 100%;
    width: 840px;
    top: -50%;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
}


@keyframes rotateImg {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(359deg);
    }
}

@media only screen and (min-width: 320px) and (max-width: 480px)  {
    /* .bannericons>div:first-child:before{
        width: 0px !important;
    } */
    .bannericons>div:first-child:before{
        width: 320px
    }
    .bannertitle h1{
        font-size: 40px;
    }
    .bannertitle p{
        font-size: 16px;
    }
    .vanta-canvas{
        height: 70vh !important;
    }
    .column{
        height: 70vh !important;
    }
    .bg{
        height: 80vh !important;
    }
    .bannersection{
        height: 70vh !important;
    }
    .bannerservice h1{
        font-size: 24px !important;
    }
}


@media only screen and (min-width: 540px) and (max-width: 540px){
    .bannericons>div:first-child:before{
        width: 444px;
    }
}

@media only screen and (min-width: 758px) and (max-width: 912px){
    .bannericons>div:first-child:before{
        width: 560px;
    }
}

@media only screen and (min-width: 1024px) and (max-width: 1280px){
    .bannericons>div:first-child:before{
        width: 765px;
    }
}

@media only screen and (min-width: 1400px) and (max-width: 1554px){
    .bannericons>div:first-child:before{
        width: 890px;
    }
}