.col2 {
    display: flex;
    justify-content: center;
    align-items: center;
}

.col1 h1 {
    font-size: 34px;
    font-weight: 700;
    color: #dddddd;

}

.par {
    font-size: 34px;
    font-weight: 700;
    line-height: 1.2;
}

.hrsolution {
    width: 100%;
}

.hrsolution .bannertitle {
    padding: 10px;
}

.hrsolution .bannertitle p {
    font-size: 24px;
    font-weight: 300;
}

.col3 {
    display: flex;
    align-items: center;
}

.par1 {
    font-size: 22px;
    font-weight: 600;
}

.role {
    font-size: 18px;
    font-weight: 400;
    color: #fff;
    line-height: 1.7;
}

.uno1 h2 {
    color: #dddddd;
    font-size: 34px;
    font-weight: 700;
}


.row2 {
    padding-top: 50px;
}

.col5 h1 {

    color: #dddddd;
    font-size: 30px;
    font-weight: 800;
}

.section h1 {
    color: #dddddd;
    font-size: 24px;
    font-weight: 700;
}

.section p {
    font-size: 18px;
    font-weight: 400;
    color: #fff;
    line-height: 1.9;
}


.row2 .itservicediv p {
    font-weight: 700;
}

.solution {
    display: flex;
    align-items: center;
}

.solution h1 {
    font-size: 18px;
    font-weight: 400;
    margin-left: 20px;
    color: #fff;
}

.rowww {
    margin-top: 50px;
}

.imggg {
    border-radius: 50%;
    border: 1px dashed rgb(250, 248, 248);
    padding: 3px;
    margin-bottom: 20px;
}

.itservicediv p {
    font-size: 34px;
}

.col5 {
    padding-right: 40px;
}


.col5-padding {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 50px;
}

.col6-padding {
    padding: 50px;
}

@media only screen and (min-width: 320px) and (max-width: 480px){
    .col6-padding{
        padding: 30px;
    }

    .col5-padding{
        padding: 30px;
    }
}

@media only screen and (min-width: 320px) and (max-width: 820px){
    #itreverserow{
        display: flex !important;
        flex-direction: column-reverse !important;
    }
}