.technologies{
    display: flex;
    justify-content: space-around;
}

.technologies h1{
    font-size: 18px;
    font-weight: 600;
    color: white;
}

.technologies1{
    display: flex;
    justify-content: space-around;
}

.technologies1 h1{
    font-size: 18px;
    font-weight: 600;
    color: white;
}

.techno>div{
    position: relative;
}

.techno>div::after{
    position: absolute;
    content: "";
    border-bottom: 2px solid rgb(255, 255, 255);
    width: 95%;
    height: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 22px;
}