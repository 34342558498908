@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700;800;900&display=swap');

* {
    font-family: 'Rubik', sans-serif;
}

.itservicecol2 {
    margin-top: 70px;
}

.itservices .bannertitle {
    padding: 10px !important;
}

.itservices .bannertitle p {
    font-weight: 300;
    font-size: 24px;
}

.itservicecol2 p {
    font-size: 22px;
    font-weight: 600;
    line-height: 1.3;
}

.itservicepara {
    color: #dddddd;
    font-weight: 700;
    font-size: 22px;
}

.itservicecol2 h1 {
    font-size: 34px;
    font-weight: 700;
    line-height: 1.3;
}



.itservicecol3 p {
    font-size: 34px;
    font-weight: 700;
    color: #dddddd;
}

.itservicecol3 h1 {
    font-size: 18px;
    font-weight: 400;
    color: #fff;
    line-height: 1.7;
}

.itservicecol4 h1 {
    font-size: 22px;
    font-weight: 600;
    line-height: 3.5;
}

.itservicerow1 {
    margin-top: 50px;
}

.itservicecol5 {
    display: flex;
    align-items: center;
}

.itservicecol5 h1 {
    margin-left: 20px;
    color: #fff;
    font-size: 18px;
    font-weight: 400;
}

.itservicecol5 img {
    border: 1px dashed rgb(247, 246, 246);
    border-radius: 50%;
    padding: 3px;
}

.itservicediv p {
    text-align: center;
    font-size: 34px;
    color: #dddddd;
    font-weight: 500;
}

.itservicerow2 {
    margin-top: 60px;
}

.itservicerow2 h1 {
    margin-bottom: 15px;
    font-size: 34px;
    font-weight: 700;
}

.itservicerow2 p {
    font-size: 34px;
    font-weight: 700;
}

.itservicecol6 {
    padding: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.itservicecol6 h1 {
    font-size: 24px;
    font-weight: 700;
    color: #dddddd;
}

.itservicecol6 p {
    font-size: 18px;
    font-weight: 400;
    color: #fff;
    line-height: 1.9;
}

.itservicecol6 img {
    border: 1px dashed rgb(255, 255, 255);
    border-radius: 50%;
    padding: 4px;
    margin-bottom: 20px;
}


@media only screen and (min-width: 320px) and (max-width: 480px) {
    .itservicecol6 {
        padding: 30px;
    }

    .itservicecol5 h1 {
        font-size: 16px;
    }
}

@media only screen and (min-width: 320px) and (max-width: 820px) {
    #itreverserow {
        display: flex !important;
        flex-direction: column-reverse !important;
    }

   
}