@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700;800;900&display=swap');

* {
    font-family: 'Rubik', sans-serif;
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}


.containers {
	max-width: 1170px;
	margin: auto;
}

.row {
	display: flex;
	flex-wrap: wrap;
}

.footer a{
	color: white !important;
	cursor: pointer;
}

ul {
	list-style: none;
}

.footer {
	background-color: #24262b;
	padding: 40px 0px 0px 0px;
}

.footer-col {
	width: 25%;
	padding: 0 15px;
}

.footer-col h4 {
	font-size: 34px;
	color: #ffffff;
	text-transform: capitalize;
	margin-bottom: 35px;
	font-weight: 500;
	position: relative;
}

.footer-col h4::before {
	content: '';
	position: absolute;
	left: 0;
	bottom: -10px;
	background-color: #bc132a;
	height: 2px;
	box-sizing: border-box;
	width: 50px;
}

.footer-col ul li:not(:last-child) {
	margin-bottom: 10px;
}

.footer ul {
	padding-left: 0%;
}

.footer-col ul li a {
	font-size: 22px;
	text-transform: capitalize;
	color: #bc132a;
	text-decoration: none;
	font-weight: 300;
	color: #bbbbbb;
	display: block;
	transition: all 0.3s ease;
}

.footer-col ul li a:hover {
	color: #bc132a;
	padding-left: 8px;
}

.footer-col .social-links a {
	display: inline-block;
	height: 40px;
	width: 40px;
	background-color: rgba(255, 255, 255, 0.2);
	margin: 0 10px 10px 0;
	text-align: center;
	line-height: 40px;
	border-radius: 50%;
	color: #ffffff;
	transition: all 0.5s ease;
}

.footer-col .social-links a:hover {
	transform: scale(1.1);
	background-color: #24262b;
}

/*responsive*/
@media(max-width: 767px) {
	.footer-col {
		width: 50%;
		margin-bottom: 30px;
	}
}

@media(max-width: 574px) {
	.footer-col {
		width: 100%;
	}
}



.foot {
	width: 100%;
	display: flex;
	align-items: top;
}

.foot section {
	/* padding:1rem 2rem; */
	width: 100%;
	vertical-align: top;
}

.foot section h3 {
	color: white;
	position: relative;
}

.foot section a {
	/* color:var(--gray); */
	color: rgb(255, 255, 255);
	display: flex;
	align-items: center;
	white-space: nowrap;
	margin: 15px 0;
}

.foot section a:hover {
	color: #bc132a;
}

.foot section:nth-child(2) a:before,
.foot section:nth-child(3) a:before {
	content: "";
	margin-right: 5px;
	color: white !important;
}

@media only screen and (min-width: 768px) and (max-width: 912px){
	.footer-col{
		width: 23%;
	}
}
.wap{
	cursor: pointer;
	position: fixed;
	bottom: 2%;
	right: 6%;
	z-index: 1;
}

@media only screen and (max-width: 768px){
	.wap{
		bottom: 3%;
		left: 6%;
	}
	
}