.send {
    font-size: 22px;
    font-weight: 700;
    padding-left: 8px;
    color: rgb(255, 255, 255);
}

@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700;800;900&display=swap');

* {
    font-family: 'Rubik', sans-serif;
}

.contactinput {
    width: 100%;
    border-top: none;
    border-left: none;
    border-right: none;
}

.contactform .css-1d3z3hw-MuiOutlinedInput-notchedOutline{
    border-color: rgba(255, 255, 255, 0.23) !important;
}

.contactform .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root{
    color: rgb(255 255 255 / 60%);
}

.contactform .css-1jy569b-MuiFormLabel-root-MuiInputLabel-root.Mui-focused{
    color: rgb(255 255 255 / 60%) !important;
}

.contactform #outlined-name-input{
    color: white !important;
}

.contactform button{
    padding: 0.5rem 3rem !important;
    border: none;
}

.contactform .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input{
    color: white !important;
}

.contactform .css-hfutr2-MuiSvgIcon-root-MuiSelect-icon{
    color: rgb(255 255 255 / 54%) !important;
}

.css-13r4j3i .MuiTextField-root {
    margin: 8px 10px !important;
    width: 46.5ch !important;
}

.contactform .css-q8hpuo-MuiFormControl-root{
    margin: 8px 0 !important;
}


.contactform .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input:focus{
    color: white !important;
}

.contactform .css-bpeome-MuiSvgIcon-root-MuiSelect-icon{
    color: rgb(255 255 255 / 54%) !important;
}

.css-1jy569b-MuiFormLabel-root-MuiInputLabel-root{
    color: rgb(255 255 255 / 60%) !important;
}

.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input{
    color: white !important;
}

#outlined-subject-input2 {
    height: 50px !important;
}

#outlined-name-input {
    color: dimgray;
}

.contactform .Contactbutton{
    margin-top: 50px !important;
}

#outlined-name-input:invalid {
    animation: justshake 0.3s forwards;
    color: red;
}

.css-igs3ac{
    border-color: rgb(255 255 255 / 60%) !important; 
}

.css-igs3ac:hover{
    border-color: rgb(255 255 255 / 60%) !important; 
}

.css-1ald77x .Mui-focused {
    color: rgb(255 255 255 / 87%) !important;
}

.css-p0rm37{
    color: rgb(255 255 255 / 60%) !important;
}

.css-1v4ccyo{
    color: rgb(255 255 255 / 87%) !important;
}


.css-1ald77x{
    color: rgb(255 255 255 / 60%) !important;
}


@keyframes justshake {
    25% {
        transform: translateX(5px);
    }

    50% {
        transform: translateX(-5px);
    }

    75% {
        transform: translateX(5px);
    }

    100% {
        transform: translateX-(5px);
    }
}

@media only screen and (min-width: 320px) and (max-width: 480px){
    .number{
        display: block !important;
    }
}

@media only screen and (min-width: 1399px) and (max-width: 1600px){
    .css-13r4j3i .MuiTextField-root{
        width: 55ch !important;
    }

}


.thankyou{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 90vh;
}

.thankyou h1{
    text-align: center;
}

.thankyou p{
    text-align: center;
}

.thankyou .thank{
    padding: 100px 200px;
    background-color: #ddd;
    border-radius: 40px;
}