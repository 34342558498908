@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700;800;900&display=swap');

* {
    font-family: 'Rubik', sans-serif;
}


.maps1{
    margin-top: 60px !important;
}

.ness {
    color: #dddddd;
    display: flex;
    margin-top: 50px;
    font-size: 34px;
    text-align: center;
    padding-bottom: 25px;
    font-weight: bold;
}

.sest {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 50px;
}

.bannertech {
    height: 270vh;
}

.valid2 {
    animation-name: mymove;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    animation-duration: 3s;
}

@keyframes mymove {
    from {
        transform: translateY(0px);
    }

    to {
        transform: translateY(20px);
    }
}

.assign {
    font-size: 34px;
    font-weight: 700;
    display: flex;
    justify-content: center;
    color: #fff;
}

.assign2 {
    font-size: 22px;
    font-weight: 500;
    color: #fff;
}

.more {
    /* margin-left: 20px; */
    color: #dddddd;
    font-weight: 700;
    font-size: 34px;
}

.more2 {
    color: #fff;
    font-weight: 300;
    font-size: 18px;
    line-height: 1.9;
}

.valid2 {
    padding-left: 130px;
}

.line {
    font-size: 22px;
    font-weight: 600;
    line-height: 2.0;
}

.web {
    display: flex;
    align-items: center;
}

.webs {
    margin-left: 20px;
    color: #fff;
    font-size: 18px;
    font-weight: 400;
    line-height: 3.5;
}

.web img {
    border: 1px dashed rgb(244, 239, 239);
    border-radius: 50%;
    padding: 3px;
}

.feat {
    color: #dddddd;
    font-size: 34px;
    font-weight: 700;
    display: flex;
    justify-content: center;
    margin-top: 90px;

}

.feat p {
    font-size: 34px;
}

.feat2 p {
    font-size: 18px;
    font-weight: 400;
}

.feat2 {
    font-size: 34px;
    font-weight: 700;
    display: flex;
    justify-content: center;
    color: #fff;
}


.business .bannertitle {
    padding: 10px;
}

.bannertitle p {
    color: #fff;
}

.brand {
    font-size: 24px;
    font-weight: 700;
    color: #dddddd;
}

.brand2 {
    font-size: 18px;
    font-weight: 400;
    color: #fff;
    line-height: 1.9;
}

.sest img {
    border: 1px dashed rgb(247, 243, 243);
    border-radius: 50%;
    padding: 3px;
    margin-bottom: 20px;
}

/* .maps {
    margin-top: 70px;
} */

@media only screen and (min-width: 320px) and (max-width: 480px){
    .bannertech{
        height: 190vh;
    }
    .sest{
        padding: 30px;
    }
    .business .bannertitle p{
        font-size: 24px;
        font-weight: 300;
    }
}

@media only screen and (min-width: 768px) and (max-width: 912px){
    .bannertech{
        height: 240vh;
    }
}

@media only screen and (min-width: 320px) and (max-width: 820px){
    #itreverserow{
        display: flex !important;
        flex-direction: column-reverse !important;
    }
}