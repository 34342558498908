@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700;800;900&display=swap');

*{
    font-family: 'Rubik', sans-serif;
}

.ports h1 {
    font-size: 18px;
    color: white !important;
    font-weight: 300;
    line-height: 1.5;
}

.port p {
    font-size: 16px;
    color: white;
    opacity: 0;
}

.port {
    opacity: 1;
}


.portfoliocontainer .title {
    color: #1a1a1a;
    text-align: center;
    margin-bottom: 10px;
}

.portfoliocontainer .content {
    position: relative;
    width: 100%;
    margin: auto;
    overflow: hidden;
}

.portfoliocontainer .content .content-overlay {
    background: rgba(0, 0, 0, 0.7);
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    opacity: 0;
    -webkit-transition: all 0.4s ease-in-out 0s;
    -moz-transition: all 0.4s ease-in-out 0s;
    transition: all 0.4s ease-in-out 0s;
}


.portfoliocontainer .content:hover .content-overlay {
    opacity: 1;
}


.portfoliocontainer .content-image {
    width: 100%;
}
/* 
.portfoliocontainer .content img{
    height: 100vh;
} */

.portfoliopadding{
    padding: 30px;
}

.portfoliocontainer .content{
    box-shadow: rgba(255, 254, 254, 0.2) 0px 12px 28px 0px;
}

.portfoliocontainer .my-5{
    margin-top: 6rem !important;
}

.portfoliocontainer .portfoliopadding1{
    margin-top: 6rem;
}

.portfoliocontainer .content-details {
    position: absolute;
    text-align: center;
    padding-left: 1em;
    padding-right: 1em;
    width: 100%;
    top: 50%;
    left: 50%;
    opacity: 0;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    -webkit-transition: all 0.3s ease-in-out 0s;
    -moz-transition: all 0.3s ease-in-out 0s;
    transition: all 0.3s ease-in-out 0s;
}

.portfoliocontainer .content:hover .content-details {
    top: 50%;
    left: 50%;
    opacity: 1;
}

.portfoliocontainer .content-details h3 {
    color: #fff;
    font-weight: 500;
    letter-spacing: 0.15em;
    margin-bottom: 0.5em;
    text-transform: uppercase;
}

.portfoliocontainer .content-details p {
    color: #fff;
    font-size: 0.8em;
}

.portfoliocontainer .fadeIn-right {
    left: 80%;
}


.portfolio .bg{
    height: 90vh;
}

/* section */

.d-flex{
    justify-content: space-evenly;
}

.Section .d-flex{
    padding-left: 0px !important;
}

.tab{
    font-size: 16px;
    cursor: pointer;
    font-weight: 200;
}

.heading{
    color: white;
    padding: 20px;
    font-size: 16px;
    cursor: pointer;
    font-weight: 200;
}

.heading.active{
    border-bottom: 2px solid white;
}

.contents{
    background: radial-gradient(#2b4363 10%, #000e23 70%);
}

.contentss{
    display: none;
}

.show-content{
    color: red !important;
}

/* section */


.portfoliowebapp .portfoliowebappcol{
    padding: 30px;
}


.portfolioapp .portfolioappcol{
    padding: 30px;
}

.carousel-control-prev{
    display: none;
}

.carousel-control-next{
    display: none;
}

.carousel-indicators{
    display: none;
}

.portfoliowebappcol{
    /* background-color: #ddd; */
    margin-top: 50px;
}


/* .portfolioappcol{
    background-color: #ddd;
} */

.portfolioappcol img{
    filter: drop-shadow(2px 4px 6px black);
}

.portfolioapprow{
    margin-top: 30px;
}

.portfoliocol1{
    display: flex;
    justify-content: center;
    align-items: center;
}

.portfoliocol1 h1{
    font-size: 34px !important ;
    color: #ddd !important;
}

.portfoliocol1 p{
    color: #ddd !important;
    font-size: 18px !important;
}

@media only screen and (min-width: 320px) and (max-width: 480px){
    .portfoliocontainer .portfoliopadding1{
        margin-top: 0px;
    }

    .portfoliocontainer .my-5{
        margin-top: 0px !important;
    }
    #itreverserow{
        display: flex !important;
        flex-direction: column-reverse !important;
    }

}

.portfolio .bannertitle p {
    color: white;
    font-size: 24px;
    font-weight: 300;
    padding-left: 10px;
    padding-bottom: 0px;
}