@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700;800;900&display=swap');

* {
  font-family: 'Rubik', sans-serif;
}


.careers {
  display: flex;
  justify-content: center;
  align-items: center;
}

.pro2 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.careersheight {
  height: 80vh;
}

.tit1 h1 {
  font-size: 50px;
  font-weight: 800;
}

.tit1 p {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 0px;
}


.bot {
  color: #dddddd;
  text-align: center;
  font-weight: 600;
  font-size: 34px;
  margin-top: 50px !important;
}

.Career .bannertitle {
  padding: 10px;
}

.Career .bannertitle p {
  color: white;
  font-size: 24px;
  font-weight: 300;
  padding-left: 10px;
  padding-bottom: 0px;
}

.careersbutton {
  display: flex;
  justify-content: space-around;
  padding-top: 10px;
}

.button {
  width: 170px;
  margin-top: 40px;
  padding-top: 30px;
  padding-bottom: 30px;
  text-align: center;
  color: #ffffff;
  /* text-transform: uppercase; */
  font-weight: 600;
  margin-left: 0px;
  margin-bottom: 30px;
  cursor: pointer;
  display: inline-block;
  font-size: 20px;
  z-index: 0;
}

.button-5 {
  width: 400px;
  border-radius: 5px;
  background-color: hsla(60, 17%, 77%, 0.822);
  background-image: hsla(60, 2%, 61%, 0.822);
  background-image: -moz-linear-gradient(top, #fff 0%, hsla(60, 2%, 61%, 0.822) 100%);
  background-image: -webkit-linear-gradient(top, #fff 0%, hsla(60, 2%, 61%, 0.822) 100%);
  background-image: linear-gradient(to bottom, #fff 0%, hsla(60, 2%, 61%, 0.822) 100%);
  background-size: 700px;
  background-repeat: no-repeat;
  background-position: 0%;
  -webkit-transition: background 300ms ease-in-out;
  transition: background 300ms ease-in-out;
}

.button-5:hover {
  background-position: -200%;
  transition: background 300ms ease-in-out;
}

.button4 {
  width: 170px;
  margin-top: 40px;
  padding-top: 30px;
  padding-bottom: 30px;
  text-align: center;
  color: #000;
  /* text-transform: uppercase */
  font-weight: 600;
  margin-left: 80;
  margin-bottom: 30px;
  cursor: pointer;
  display: inline-block;
  font-size: 20px;
}

.button4 {
  width: 400px;
  border-radius: 5px;
  background-color: hwb(289 59% 23% / 0.822);
  background-image: hwb(290 37% 24% / 0.822);
  background-image: -moz-linear-gradient(top, #fff 0%, hwb(290 37% 24% / 0.822) 100%);
  background-image: -webkit-linear-gradient(top, #fff 0%, hwb(290 37% 24% / 0.822) 100%);
  background-image: linear-gradient(to bottom, #fff 0%, hwb(290 37% 24% / 0.822) 100%);
  background-size: 700px;
  background-repeat: no-repeat;
  background-position: 0%;
  -webkit-transition: background 300ms ease-in-out;
  transition: background 300ms ease-in-out;
}

.button-5:hover {
  background-position: -200%;
  transition: background 300ms ease-in-out;
}

.hire {
  color: #dddddd;
  font-size: 34px;
  font-weight: 600;
  margin-top: 30px;
  text-align: center;
}

.hire2 {
  color: white;
  font-size: 18px;
  font-weight: 350;
  line-height: 2;
  margin-top: 30px;
  padding: 20px;
}

