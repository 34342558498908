/* body {
    background: #EEF4FF;
    margin: 0;
    font-family: sans-serif;
    color: #333;
} */

.bannertech .spacer {
    width: 100%;
    height: 80vh;
    background: #ddd;
}

.bannertech .galleryh1 {
    background-color:#24262b;
    padding: 20px 0px;
    display: flex;
    align-items: end;
    color: white;
}

.bannertech .bannerst{
    position: sticky;
    top: 0;
    z-index: -1;
}

.bannertech .gallery h1 {
    font-size: 28px;
    font-weight: 600;
}

.bannertech .gallery ul{
    padding-left: 4.5rem;
}

.bannertech .gallery li {
    font-size: 18px;
    line-height: 2.5 !important;
    list-style-type: none;
    font-weight: 400;
    color: white;
}


.bannertech .gallery {
    display: flex;
    margin-top: 100px;
    /* outline:1px solid red; */
}

.bannertech .left {
    width: 50%;
}

.bannertech .galleryh1img{
    margin-left: 20px;
}

.bannertech .right {
    height: 100vh;
    /* outline:1px solid purple; */
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.bannertech .desktopContent {
    margin: auto;
    width: 80%;
}

.bannertech .desktopContentSection {
    min-height: 100vh;
    /* outline:1px solid green; */
    /* display: flex; */
    /* flex-direction: column;
    justify-content: center; */
    margin-left: 0 !important;
}

.bannertech .galleryh1 img{
    border: 1px dashed rgb(255, 255, 255);
    border-radius: 50%;
    padding: 3px;
}

.bannertech .desktopPhotos {
    width: 40vw;
    height: 40vw;
    position: relative;
    overflow: hidden;
    /* box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.4); */
}

.bannertech .desktopPhoto {
    position: absolute;
    width: 100%;
    height: 100%;
}




.bannertech .gallery .red {
    background: url("/src/Assests/icons/itservicesimages.jpg") no-repeat;
    background-size: contain;
}


.bannertech .green {
    background: url("/src/Assests/icons/businessimages.jpg") no-repeat;
    background-size: contain;
}


.bannertech .pink {
    background: url("/src/Assests/icons/hrsolutionimages.jpg") no-repeat;
    background-size: contain;
}


/* small screen / mobile layout */
.bannertech .mobileContent {
    display: none;
    width: 80vw;
}

.bannertech .mobilePhoto {
    width: 80vw;
    height: 80vw;
    margin-top: 5em;
    border-radius: 6vw;
}






/* defines styles for screens up to 599px wide */
/* @media screen and (max-width: 599px) {
    .left {
        display: none;
    }

    .right {
        height: auto;
        width: 100%;
        align-items: center;
    }

    .desktopPhotos {
        display: none;
    }

    .mobileContent {
        display: block;
    }


} */

@media only screen and (min-width: 320px) and (max-width: 480px){
    .bannertech .gallery{
        margin-top: 60px !important;
    }
    .pin-spacer{
        display: none !important;
    }
    .bannertech .left{
        width: 100% !important;
    }
    .bannertech .desktopContent{
        width: 100% !important;
    }
    .bannertech .desktopContentSection{
        min-height: 67vh;
    }
}


@media only screen and (min-width: 768px) and (max-width: 912px){
    .pin-spacer{
        display: none !important;
    }

    .bannertech .left{
        width: 100%;
    }

    
}


