.privacypolicy h1{
    font-size: 34px;
    font-weight: 500;
    color: #fff;
}

.privacypolicy h4{
    font-weight: 500;
    color: #fff;
}

.privacypolicy p{
    color: white;
    font-size: 18px;
    text-decoration: none;
    font-weight: 300;
    word-spacing: 2px;
    line-height: 1.9;
}

.privacypolicy a{
    word-spacing: 2px;
    line-height: 1.9;
    color: #fff;
    text-decoration: none;
}

.privacypolicy{
    margin-top: 50px;
}