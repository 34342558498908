.gallery1 .spacer {
    width: 100%;
    height: 50vh;
    background: #ddd;
}

.gallery1 h1 {
    font-size: 34px;
    font-weight: 800;
}

.gallery1 p {
    font-size: 22px;
    line-height: 1.4;
    font-weight: 600;
}


.gallery1 {
    display: flex;
    /* outline:1px solid red; */
}

.gallery1 .left {
    width: 50%;
}

.gallery1 .right {
    height: 70vh;
    /* outline:1px solid purple; */
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.gallery1 .desktopContent {
    margin: auto;
    width: 80%;
}

.gallery1 .desktopContentSection {
    min-height: 70vh;
    /* outline:1px solid green; */
    display: flex;
    /* align-items: center; */
    flex-direction: column;
    /* justify-content: center; */
    /* margin-left: 145px; */

}

.gallery1 .desktopContentSection h1 {
    font-weight: bold;
    font-size: xx-large;
}

.gallery1 .desktopPhotos {
    width: 34vw;
    height: 34vw;
    border-radius: 20px;
    position: relative;
    overflow: hidden;
    /* box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.4); */
}

.gallery1 .desktopPhoto {
    position: absolute;
    width: 100%;
    height: 100%;
    background-size: contain;
}

.gallery1 .red {
    background: url("../../Assests/aboutus/Social\ ideas.gif");
    background-size: contain;
    margin-left: 60px;

    animation-name: mymove;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    animation-duration: 3s;
}

@keyframes mymove {
    from {
        transform: translateY(0px);
    }

    to {
        transform: translateY(20px);
    }
}

.gallery1 .green {
    background: url("../../Assests/aboutus/Launching.gif");
    background-size: contain;
}

.gallery1 .pink {
    background: url("../../Assests/aboutus/Make\ it\ rain.gif");
    background-size: contain;
}


/* small screen / mobile layout */
.mobileContent {
    display: none;
    width: 80vw;
}

.mobilePhoto {
    width: 80vw;
    height: 80vw;
    margin-top: 5em;
    border-radius: 6vw;
}