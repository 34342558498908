.Homebutton {
    margin-top: 30px;
    padding: 40px;
    background: #dddddd ;
}

.Homebutton h1 {
    text-align: center;
    font-size: 28px;
    font-weight: 700;
    color: #3e3e3e;
    margin-bottom: 40px;
}

.Homebutton button {
    background-color: black !important;
    color: #fff;
    border-radius: 6px;
    font-size: 18px !important;
    padding: 12px 16px !important;
}

.Homebutton button:hover{
    box-shadow: rgba(34, 34, 36, 0.2) 0px 7px 29px 0px;
}

.Contactbutton {
    display: flex;
    justify-content: center;
}