.map h1{
    text-align: center;
    font-size: 28px;
    font-weight: 600;
    color: rgb(255, 255, 255);
}

.map p{
    text-align: center;
    font-size: 18px;
    font-weight: 400;
    color: white;
}

.maps{
    margin-top: 30px !important;
}

.map{
    margin-top: 40px;
}


@media only screen and (min-width: 320px) and (max-width: 480px){
    .maps{
        margin-top: -70px !important;
    }
}