.ClientReview p{
    font-size: 18px;
    font-weight: 400;
    margin-top: 20px;
}

.ClientreviewDiv{
    display: flex;
    justify-content: center;
    align-items: center;
}

.ClientReview h1{
    font-size: 28px;
    text-align: center;
    font-weight: 600;
    margin-bottom: 40px;
}
