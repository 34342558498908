@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700;800;900&display=swap');

* {
    font-family: 'Rubik', sans-serif;
}

.aboutus {
    background: #24262b !important;
}

.aboutus .bannertitle{
    padding: 10px !important;
   
}

.vanta-canvas{
    height: 90vh !important;
    width: 100% !important;
}


.aboutus .bannertitle p{
    color: white;
    font-size: 24px;
    font-weight: 300;
    padding-left: 10px;
    padding-bottom: 0px;
}

.aboutus .Contactbutton button{
    background: #dddddd !important;
    padding: 10px 20px;
    border: none;
    border-radius: 3px;
}

.aboutus .Contactbutton{
    justify-content: start !important;
    padding-left: 20px !important;
}

.aboutus .animated-typing {
    text-align: center;
    font-size: 34px;
    padding: 50px 0px 0px 0px;
    color: #bc132a;
    font-weight: 800;

}

.aboutus {
    background-color: white;
}

.aboutus .abo {
    padding-top: 0px !important;
}

.aboutus .para p {
    text-align: center;
    font-size: 34px;
    padding-top: 20px;
    font-weight: 800;
    /* margin-left: 40px */
}

.aboutus .title h1 {
    text-align: center;
    margin-top: 40px;
    font-weight: bold;
}

.aboutus .title {
    justify-content: center;
    font-weight: 800;
    /* background: url("../src/assets/christmas_-_12882 (Original).gif") !important; */
    /* color: #fff; */
}

.fig p {
    color: white;
    font-size: 18px;
    font-weight: 350;
    line-height: 2;
    margin-top: 30px;
    padding: 20px;
}

.aboutus .heading2 h1 {
    color: #dddddd;
    font-size: 34px;
    font-weight: 600;
    margin-top: 30px;
    text-align: center;
}

.aboutus .var {
    overflow: hidden;
}

.aboutus .par2 {
    margin-right: 66px;
}

.aboutus .par1 {
    margin-right: 86px;
}

.aboutus .par1 {
    animation-name: mymove;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    animation-duration: 3s;
}

@keyframes mymove {
    from {
        transform: translateY(0px);
    }

    to {
        transform: translateY(20px);
    }
}

.aboutus .par2 {
    animation-name: mymove;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    animation-duration: 3s;
}

@keyframes mymove {
    from {
        transform: translateY(0px);
    }

    to {
        transform: translateY(20px);
    }
}

.aboutus .extra {
    display: none;
}

.aboutus .p {
    display: inline;
    /* padding-left: 60px; */
}



.button--nina {
    padding: 0 2em;
    background: #dddddd !important;
    color: #000000 !important;
    overflow: hidden;
    -webkit-transition: background-color 0.3s;
    transition: background-color 0.3s;
    border-radius: 8px;
    height: 100px;
}

.button--nina.button--inverted {
    background: #000000;
    color: #dddddd !important;
}

.button--nina>span {
    display: inline-block;
    padding: 1em 0;
    opacity: 0;
    color: #000000;
    -webkit-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0);
    -webkit-transition: -webkit-transform 0.3s, opacity 0.3s;
    transition: transform 0.3s, opacity 0.3s;
    -webkit-transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
    transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
}

.button--nina::before {
    content: attr(data-text);
    position: absolute;
    background-color: #dddddd !important;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding-top: 40px;
    font-size: 30px;
    -webkit-transition: -webkit-transform 0.5s, opacity 0.5s;
    transition: transform 0.5s, opacity 0.5s;
    -webkit-transition-timing-function: cubic-bezier(0.75, 0, 0.145, 1);
    transition-timing-function: cubic-bezier(0.75, 0, 0.145, 1);
}

.button--nina:hover {
    background-color: #dddddd !important;
}

.button--nina:hover::before {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
}

.button--nina:hover>span {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.button--nina:hover>span:nth-child(1) {
    -webkit-transition-delay: 0.045s;
    transition-delay: 0.045s;
}

.button--nina:hover>span:nth-child(2) {
    -webkit-transition-delay: 0.09s;
    transition-delay: 0.09s;
}

.button--nina:hover>span:nth-child(3) {
    -webkit-transition-delay: 0.135s;
    transition-delay: 0.135s;
}

.button--nina:hover>span:nth-child(4) {
    -webkit-transition-delay: 0.18s;
    transition-delay: 0.18s;
}

.button--nina:hover>span:nth-child(5) {
    -webkit-transition-delay: 0.225s;
    transition-delay: 0.225s;
}

.button--nina:hover>span:nth-child(6) {
    -webkit-transition-delay: 0.27s;
    transition-delay: 0.27s;
}

.button--nina:hover>span:nth-child(7) {
    -webkit-transition-delay: 0.315s;
    transition-delay: 0.315s;
}

.button--nina:hover>span:nth-child(8) {
    -webkit-transition-delay: 0.36s;
    transition-delay: 0.36s;
}

.button--nina:hover>span:nth-child(9) {
    -webkit-transition-delay: 0.405s;
    transition-delay: 0.405s;
}

.button--nina:hover>span:nth-child(10) {
    -webkit-transition-delay: 0.45s;
    transition-delay: 0.45s;
}

.box {
    /* padding: 4.5em 0; */
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-justify-content: center;
    justify-content: center;
}

.bg-1 {
    background: #24262b;
    color: #ffd8dd !important;
}

.button {
    float: left;
    min-width: 350px;
    max-width: 350px;
    display: block;
    /* margin: 1em; */
    /* padding: 1em 2em; */
    border: none;
    background: none;
    color: inherit;
    /* vertical-align: middle; */
    position: relative;
    z-index: 1;
    /* -webkit-backface-visibility: hidden; */
    -moz-osx-font-smoothing: grayscale;
    height: 115px;
}

.button:focus {
    outline: none;
}

.button>span {
    /* vertical-align: middle; */
    font-size: 30px;
}

.box bg-1 {
    background-color: #ffd8dd !important;
}

@media only screen and (min-width: 320px) and (max-width: 480px){
    .vanta-canvas{
        height: 70vh !important;
    }
   
}


.Blank{
    height: 100vh;
    background-color: #24262b;
}