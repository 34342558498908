@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700;800;900&display=swap');

* {
  font-family: 'Rubik', sans-serif;
}

.animated-typing2 {
  text-align: center;
  font-size: 40px;
  padding-bottom: 25px;
  color: #dddddd;
  font-weight: bold;
  margin-top: 40px;
}

*,
*:before,
*:after {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  font-size: 16px;
  font-family: 'Raleway', sans-serif;
}

.main-title {
  color: #2d2d2d;
  text-align: center;
  text-transform: capitalize;
  padding: 0.7em 0;
}

.ctn {
  padding: 1em 0;
  float: left;
  width: 50%;
}

@media screen and (max-width: 640px) {
  .ctn {
    display: block;
    width: 100%;
  }
}

@media screen and (min-width: 900px) {
  .ctn {
    width: 33.33333%;
  }
}

.ctn .title {
  color: #1a1a1a;
  text-align: center;
  margin-bottom: 10px;
}

.hike {
  position: relative;
  width: 90%;
  max-width: 400px;
  margin: auto;
  overflow: hidden;
  filter: grayscale(100%);
}

.hike .hike-overlay {
  background: rgba(3, 3, 3, 0.7);
  position: absolute;
  height: 30%;
  width: 100%;
  left: 0;
  top: 70%;
  bottom: 0;
  right: 0;
  opacity: 0;
  transition: all 0.4s ease-in-out 0s;
}

.hike:hover .hike-overlay {
  opacity: 1;
}

.hike-image {
  width: 100%;
}

.hike:hover {
  filter: grayscale(0%);
}

.hike-details {
  position: absolute;
  text-align: center;
  padding-left: 1em;
  padding-right: 1em;
  width: 100%;
  top: 50%;
  left: 50%;
  opacity: 0;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}

.hike:hover .hike-details {
  top: 50%;
  left: 50%;
  opacity: 1;
}

.hike-details h3 {
  color: #fff;
  font-weight: 500;
  letter-spacing: 0.15em;
  text-transform: uppercase;
  margin-top: 100%;
}

.hike-details p {
  color: #fff;
  font-size: 0.8em;
}

.fadeIn-bottom {
  top: 80%;
}

.fadeIn-top {
  top: 20%;
}

.fadeIn-left {
  left: 20%;
}

.fadeIn-right {
  left: 80%;
}

.noope {
  justify-content: center;
  display: flex;
}


.ctn {
  padding: 1em 0;
  float: left;
  width: 50%;
}

@media screen and (max-width: 640px) {
  .ctn {
    display: block;
    width: 100%;
  }
}

@media screen and (min-width: 900px) {
  .ctn {
    width: 33.33333%;
  }
}

.ctn2 .title2 {
  color: #1a1a1a;
  text-align: center;
  margin-bottom: 10px;
}

.hike2 {
  position: relative;
  width: 90%;
  max-width: 400px;
  margin: auto;
  overflow: hidden;
  filter: grayscale(100%);
}

.hike2:hover {
  filter: grayscale(0%);
}

.hike2 .hike-overlay2 {
  background: rgba(3, 3, 3, 0.7);
  position: absolute;
  height: 40%;
  width: 100%;
  left: 0;
  top: 60%;
  bottom: 0;
  right: 0;
  opacity: 0;
  transition: all 0.4s ease-in-out 0s;

}

.hike2:hover .hike-overlay2 {
  opacity: 1;
}

.hike-image2 {
  width: 100%;
}

.hike-details2 {
  position: absolute;
  text-align: center;
  padding-left: 1em;
  padding-right: 1em;
  width: 100%;
  top: 50%;
  left: 50%;
  opacity: 0;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}

.hike2:hover .hike-details2 {
  top: 50%;
  left: 50%;
  opacity: 1;
}

.hike-details2 h3 {
  color: #fff;
  font-weight: 500;
  letter-spacing: 0.15em;
  margin-bottom: 0.5em;
  text-transform: uppercase;
  margin-top: 100%;
}

.hike-details2 p {
  color: #fff;
  font-size: 0.8em;
}

.noope2 {
  justify-content: center;
  display: flex;
  margin-top: 50px;
}

*,
*:before,
*:after {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  font-size: 16px;
  font-family: 'Raleway', sans-serif;
}

.main-title {
  color: #2d2d2d;
  text-align: center;
  text-transform: capitalize;
  padding: 0.7em 0;
}

.fadeIn-bottom2 {
  top: 80%;
}

.fadeIn-top {
  top: 20%;
}

.fadeIn-left {
  left: 20%;
}

.noope3 {
  justify-content: center;
  display: flex;
  margin-top: 30px;
}


.team .bannertitle {
  padding: 10px !important;
}

.team .bannertitle p {
  color: white;
  font-size: 24px;
  font-weight: 300;
}