.let {
    font-size: 50px;
    font-weight: 800;
}

.title {
    display: flex;
    justify-content: center;
    align-items: center;
}

.contactus .con h1 {
    font-size: 34px !important;
}

.contact {
    display: flex;
    justify-content: center;
    align-items: center;
}

/* .address{
    padding-left: 100px;
} */
/* .address2{
    padding-left: 70px;
} */
.contactus .col {
    padding-top: 50px;
}

.address .ind {
    font-weight: 700;
    font-size: 34px;
}

.address2 .dub {
    font-weight: 700;
    font-size: 34px;
}

.address h1 {
    font-size: 34px !important;
}

.address2 h1 {
    font-size: 34px !important;
}

.chennai h2 {
    font-size: 18px;
    font-weight: 300;
}

.hyd h2 {
    font-size: 18px;
    font-weight: 300;
}

.bel {
    font-size: 18px;
    color: white;
    font-weight: 400;
}

.number {
    display: flex;
    /* justify-content: center; */
    font-size: 22px;
    color: white;
}

.pad {
    padding-top: 40px;
   
}

.exp {
    font-size: 22px !important;
}

.hyd h1 {
    margin-top: 30px;
    color: white;
}
.hyd h2{
    color: white;
}

.chennai h1 {
    margin-top: 30px;
    color: white;
}
.chennai h2{
    color: white;
}

.dis{
    padding-left: 115px;
}
.glass{
    background: rgba(255, 255, 255, 0.25);
    box-shadow: 0 8px 32px 0 rgb(93 93 93 / 37%);
    backdrop-filter: blur(4px);
    border-radius: 10px;
    border: 1px solid rgba(155, 155, 155, 0.18);
    padding: 50px;
    background-color: #212529;
}
.talk{
    color: white !important;
    padding-left: 8px !important;
}
.ind{
    color: white;
}
.dub{
    color: white;
}

.chennai h1{
    font-size: 24px !important;
}

.hyd h1{
    font-size: 24px !important;
}

.dis h1{
    color: white;
}

/* .dis{
    display: flex;
    align-items: end;
} */


.contactuscontainer{
    margin-top: 70px;
}

@media only screen and (min-width: 320px) and (max-width: 480px){
    .dis{
        padding-left: 30px;
    }
}