.slick-arrow {
    display: none !important;
}

.SimpleSliders h1 {
    font-size: 22px;
    font-weight: 500;
    text-align: center;
    margin-bottom: 20px;
    color: #24262b;
}

.SimpleSliders {
    margin-top: 50px;
    padding: 50px;
    background: #dddddd;
}

.SimpleSliders button {
    background-color: transparent !important;
}

@media only screen and (min-width: 320px) and (max-width: 480px){
    .SimpleSliders{
        margin-top: 0px;
        padding: 40px;
    }
}