@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700;800;900&display=swap');

*{
    font-family: 'Rubik', sans-serif;
}

body{
    background: #24262b;
}

/* .card {
    background: #fff;
    overflow: hidden;
    width: 100%;
    border-radius: 0px;
    border-bottom: 0px;
}

.card__img {
    position: relative;
    height: 90vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
} */

/* .bannerlogo {
    animation: rotateImg 4s linear infinite;
} */

/* @keyframes rotateImg {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(359deg);
    }
} */

/* @keyframes rotateImg {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
} */

/* .card__img>img {
    width: 10rem;
    position: relative;
    z-index: 1;
    pointer-events: none;
}

.card__grid-effect {
    position: absolute;
    z-index: 0;
    inset: 0;
    display: grid;
    grid-template-columns: repeat(10, 1fr);
    grid-template-rows: repeat(10, 1fr);
    opacity: 1;
}

.card__grid-effect-tile {
    position: relative;
}

.card__grid-effect-tile::before {
    content: "";
    color: #ff0000;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 0.3rem;
    width: 0.3rem;
    border-radius: 50%;
    background: #bc132a;
    transition: 500ms linear all;
}

.card__grid-effect-tile:hover::before {
    height: 3rem;
    width: 3rem;
    transition: 70ms linear all;

}

.card__grid-effect-tile:hover+.card__grid-effect-tile::before,
.card__grid-effect-tile:hover+.card__grid-effect-tile+.card__grid-effect-tile::before {
    height: 2.4rem;
    width: 2.4rem;
    transition: 100ms linear all;

}

.card h1 {
    color: rgb(0, 0, 0);
    font-size: 34px;
    font-weight: 700;
    text-transform: uppercase;
    z-index: 1;
}

.bannertitle {
    display: flex;
    justify-content: center;
    align-items: center;
}

.bannertitle p {
    color: black;
    font-size: 22px;
    font-weight: 500;
    z-index: 1;
} */


.card {
    background: #fff;
    border-radius: 0px;
    overflow: hidden;
    width: 100% !important;
    border-bottom: 0px;
}

.card__img {
    position: relative;
    height: 75vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.card__img>img {
    width: 10rem;
    position: relative;
    z-index: 1;
    pointer-events: none;
}

.card__grid-effect {
    position: absolute;
    z-index: 0;
    inset: 0;
    display: grid;
    grid-template-columns: repeat(10, 1fr);
    grid-template-rows: repeat(10, 1fr);
}

.card__grid-effect-tile {
    position: relative;
}

.card__grid-effect-tile:before {
    content: "";
    color: #A9C9FF;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 0rem;
    width: 0rem;
    border-radius: 50%;
    background: #bc132a;
    transition: 500ms linear all;
    opacity: .3;
}

/* body{
    cursor: none;
} */

.card__grid-effect-tile.current:before {
    height: 2.8rem;
    width: 2.8rem;
    transition: 70ms linear all;
}

.card__grid-effect-tile.currentstep1:before {
    height: 1.6rem;
    width: 1.6rem;
    transition: 100ms linear all;
}

.card__grid-effect-tile.currentstep2:before {
    height: 1rem;
    width: 1rem;
    transition: 150ms linear all;
}

.card h1 {
    color: rgb(0, 0, 0);
    font-size: 34px;
    font-weight: 800;
    text-transform: uppercase;
    line-height: 1.5;
}


.bannertitle h1{
    color: #fff;
    font-size: 72px;
    font-weight: 400;
   
}

.column{
    height: 90vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.bannertitle{
    background: #0000000f;
    padding: 10px;
}

/* .bannersection{
    background-color: #00000080 !important;
} */
/* 
.bannertitle {
    display: flex;
    justify-content: center;
    align-items: center;
    
} */

/* .bannertitles{
    z-index: 1;
} */

/* .bannersections{
    background-color: white;
} */

.bannertitle p {
    color: black;
    font-size: 22px;
    font-weight: 400;
    line-height: 1.9;
}

.bg{
    height: 100vh;
}

.clientlogomain img{
    width: 40%;
}

.clientlogomain .container{
    padding: 10px;
}

.clientlogomain button{
    display: none !important;
}


